

































































import Vue from 'vue';

// Utilities

export default Vue.extend({
  name: 'StaffAppBar',

  methods: {
    gotoPage(routeName: string) {
      this.$router.push({ name: routeName });
    },

    drawerAction() {
      this.$emit('drawerChange');
    },
  },
});
